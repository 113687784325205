body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-position: 0px -30vh;
  background-repeat: no-repeat;
  background-image: radial-gradient(50% 50% at 50% 50%, rgb(33 50 229 / 10%) 0%, rgba(33, 36, 41, 0) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animated-container {
  justify-content: center;
}

.gradient-block {
  color: rgba(255,255,255,0.9);
  background: linear-gradient(-45deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5);
  background-size: 300%;
  animation: gradient-shift 8s linear infinite;
}

.gradient-block-inner {
  width: 100%;
  height: 100%;
  color: rgba(255,255,255,0.9);
  background: linear-gradient(-45deg, #FFA63D, #FF3D77, #338AFF, #3CF0C5);
  background-size: 300%;
  animation: gradient-shift 8s linear infinite;
  position: absolute;
  z-index: -1;
  filter: blur(20px);
  opacity: 0.7;
}

.stepper-icon-container-inner {
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  border-radius: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepper-icon-container-dark-mode {
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  border-radius: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #131313;
}

.simple-gradient-block {
  background: linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%);
}

.flex-center-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-center-vertical {
  display: flex;
  align-items: center;
}

.flex-center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left-align-center {
  display: flex;
  justify-content: left;
  align-items: center;
}

.transition-opacity {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.opacity-button {
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover { 
    opacity: 1
  }
}

.qr-code-scanner-video {
  div {
    max-width: 600px!important;
    width: calc(100vw - 65px)!important;
  }
  video {
    top: 0px!important;
    left: 50%!important;
    height: 100%!important;
    display: block!important;
    overflow: hidden!important;
    position: absolute!important;
    transform: scaleX(-1)translateX(50%)!important;
    width: 800px!important;
  }
}

.no-decorate {
  text-decoration: none;
}

.matrix-text {
  color: #32cd32
}

.otp-inner-shadow {
  box-shadow: inset 0 0 10px #000000;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 30px;
  overflow: hidden;
  z-index: 1;
}

.otp-input-container {
  overflow: hidden;
  border-radius: 30px;
  max-height: 187px;
  border: none;
  z-index: 2;
  position: relative;
}

.disable-number-spinners {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type="number"] {
      -moz-appearance: textfield;
  }
}

@keyframes gradient-shift {
  0% {
    background-position: 0% 50%
  }
  50% {   
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}